import { Helmet } from "react-helmet";
import MapboxGLMap from "../components/Map";

interface EntryScreenHandlerProps {
    theme: string;
}

const HomeScreen = ({ theme }: EntryScreenHandlerProps) => {
    let mapStyle = theme === "light" ? "streets-v11" : "dark-v10";

    return (
        <>
            <Helmet>
                <title>Home</title>
                <meta
                    name="description"
                    content="PathLess, a web application that randomly generate routes for you."
                />
            </Helmet>
            <MapboxGLMap theme={mapStyle} />
        </>
    );
};

export default HomeScreen;
