import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import axios from "axios";

const generatePoint = (center, radius, waypoints) => {
    let adj = turf.lengthToDegrees(radius, "kilometers");
    let upper = center[1] + adj;
    let lower = center[1] - adj;
    let right = center[0] + adj;
    let left = center[0] - adj;

    let point = turf.randomPoint(1, { bbox: [upper, right, lower, left] })
        .features[0].geometry.coordinates;
    let works = true;
    for (let index = 0; index < waypoints.length; ++index) {
        if (
            turf.distance(waypoints[index], point, { units: "degrees" }) <
                adj / 2.5 ||
            turf.distance(center, point, { units: "degrees" }) < adj / 2.5
        ) {
            works = false;
        }
    }
    if (works) {
        return point;
    } else {
        return generatePoint(center, radius, waypoints);
    }
};

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

let requests;
let data;
let route;
let geojson;
let distance = 0;
let waypoints = [];

const createPath = async (start, totalDistance, profile) => {
    let findNewPath = true;
    requests = 0;
    while (findNewPath) {
        distance = 0;
        waypoints = [];
        let waypointsURL = "";
        while (distance < totalDistance) {
            if (requests >= Math.min(totalDistance, 5)) {
                return await createPath(start, totalDistance, "cycling");
            }

            waypoints.push(generatePoint(start, totalDistance, waypoints));

            waypointsURL +=
                waypoints[waypoints.length - 1][1] +
                "," +
                waypoints[waypoints.length - 1][0] +
                ";";

            let url =
                "https://api.mapbox.com/directions/v5/mapbox/" +
                profile +
                "/" +
                start[0] +
                "," +
                start[1] +
                ";" +
                waypointsURL +
                start[0] +
                "," +
                start[1] +
                "?steps=true&geometries=geojson&access_token=" +
                mapboxgl.accessToken;

            const response = await axios.get(url);
            const json = response.data;
            data = json.routes[0];
            route = data.geometry.coordinates;
            geojson = {
                type: "Feature",
                properties: {},
                geometry: {
                    type: "LineString",
                    coordinates: route,
                },
            };
            requests++;
            distance = data.distance / 1000;
        }
        findNewPath = Math.abs(distance - totalDistance) < 2 ? false : true;
    }
    return [geojson, data];
};

export default createPath;
