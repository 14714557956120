import "../App.css";
import { BaseProvider, createDarkTheme, createLightTheme } from "baseui";
import Header from "../components/Header";
import HomeScreen from "../screens/HomeScreen";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { User } from "@firebase/auth-types";
import { THEME } from "./Constants";
import { Route, Routes } from "react-router-dom";
import AboutScreen from "../screens/AboutScreen";

const engine = new Styletron();

export default function EntryScreenHandler() {
    const auth = getAuth();
    const [initializing, setInitializing] = useState(true);
    const [user, setUser] = useState<User | null>(null);
    let userAsBoolean: boolean = user ? true : false;
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser: any) => {
            setUser(currentUser);
            if (initializing) setInitializing(false);
        });
        return unsubscribe;
    }, [setUser]);

    const [theme, setTheme] = useState(THEME.dark);

    let routes = [
        <Route
            key="homepage"
            path="/"
            element={<HomeScreen theme={theme} />}
        />,
        <Route
            key="aboutpage"
            path="/about"
            element={<AboutScreen theme={theme} />}
        />,
        <Route
            path="*"
            key="404page"
            element={<AboutScreen theme={theme} />}
        />,
    ];

    const signedInRoutes = [
        <Route
            key="accountpage"
            path="/account"
            element={<AboutScreen theme={theme} />}
        />,
    ];

    const signedOutRoutes = [
        <Route
            key="signinpage"
            path="/signin"
            element={<AboutScreen theme={theme} />}
        />,
    ];

    routes = userAsBoolean
        ? signedInRoutes.concat(routes)
        : signedOutRoutes.concat(routes);

    const primitives = {
        primaryFontFamily: "Raleway",
    };

    const LightTheme = createLightTheme(primitives);
    const DarkTheme = createDarkTheme(primitives);

    return (
        <StyletronProvider value={engine}>
            <BaseProvider
                theme={theme === THEME.light ? LightTheme : DarkTheme}
            >
                <Header updateTheme={setTheme} parentTheme={theme} />
                <Routes>{routes}</Routes>
            </BaseProvider>
        </StyletronProvider>
    );
}
