import { StyledLink } from "baseui/link";
import { Button } from "baseui/button";
import { Paragraph1 } from "baseui/typography";
import Logo from "../img/an-logo-blue.png";
import Icon from "../img/icon.png";
import { useState } from "react";
import { RiLightbulbFlashFill, RiLightbulbFlashLine } from "react-icons/ri";
import { THEME } from "../utils/Constants";
import { Link } from "react-router-dom";
import { Drawer, SIZE } from "baseui/drawer";
import { CgMenuMotion } from "react-icons/cg";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";

interface HeaderProps {
    updateTheme: (theme: string) => void;
    parentTheme: string;
}

const Header = ({ updateTheme, parentTheme }: HeaderProps) => {
    const [theme, setTheme] = useState(parentTheme);

    const updateThemeHandler = () => {
        // not sure why the following code doesn't work
        // const searchBar = document.getElementsByClassName(
        //     "mapboxgl-ctrl-geocoder mapboxgl-ctrl"
        // )[0] as HTMLElement;
        // const searchBarText = document.getElementsByClassName(
        //     "mapboxgl-ctrl-geocoder--input"
        // )[0] as HTMLElement;
        // console.log(searchBar);
        // console.log(searchBarText);
        // if (searchBar) {
        //     console.log(searchBar.style.background);
        //     if ("black" === searchBar.style.background) {
        //         console.log("1");
        //         searchBar.style.background = "white";
        //         searchBarText.style.color = "rgba(0, 0, 0, 0.25)";
        //     } else {
        //         console.log("2");
        //         searchBar.style.background = "black";
        //         searchBarText.style.color = "rgba(255, 255, 255, 0.75)";
        //     }
        // }
        const newTheme = theme === THEME.dark ? THEME.light : THEME.dark;
        setTheme(newTheme);
        updateTheme(newTheme);
    };

    const [isOpen, setIsOpen] = useState(false);

    const Header = () => {
        return (
            <>
                <Button
                    $style={{
                        position: "absolute",
                        zIndex: 1,
                        marginTop: "10px",
                        marginLeft: "10px",
                        borderRadius: "50%",
                    }}
                    onClick={() => setIsOpen(true)}
                >
                    <CgMenuMotion />
                </Button>
                <Drawer
                    overrides={{ Root: { style: { zIndex: 1000 } } }}
                    isOpen={isOpen}
                    autoFocus
                    size={SIZE.auto}
                    onClose={() => setIsOpen(false)}
                >
                    <FlexGrid flexDirection="column" flexGridRowGap="20px">
                        <FlexGridItem>
                            <img
                                style={{ maxHeight: "42px" }}
                                src={Icon}
                                alt="logo"
                            />
                        </FlexGridItem>
                        <FlexGridItem>
                            <Paragraph1>PathLess</Paragraph1>
                        </FlexGridItem>
                        <FlexGridItem>
                            <Link to="/" onClick={() => setIsOpen(false)}>
                                <StyledLink>Home</StyledLink>
                            </Link>
                        </FlexGridItem>
                        <FlexGridItem>
                            <Link to="/about" onClick={() => setIsOpen(false)}>
                                <StyledLink>About</StyledLink>
                            </Link>
                        </FlexGridItem>
                        {/* <FlexGridItem>
                            <Link
                                to="/account"
                                onClick={() => setIsOpen(false)}
                            >
                                <StyledLink>Account</StyledLink>
                            </Link>
                        </FlexGridItem> */}
                        <FlexGridItem>
                            <a
                                href="https://aniruthn.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    style={{
                                        maxHeight: "42px",
                                    }}
                                    src={Logo}
                                    alt="logo"
                                />
                            </a>
                        </FlexGridItem>
                        <FlexGridItem>
                            <Button onClick={updateThemeHandler}>
                                {theme === THEME.dark ? (
                                    <RiLightbulbFlashFill />
                                ) : (
                                    <RiLightbulbFlashLine />
                                )}
                            </Button>
                        </FlexGridItem>
                    </FlexGrid>
                </Drawer>
            </>
        );
    };

    return <Header />;
};

export default Header;
