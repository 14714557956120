import {
    Display3,
    H5,
    Label1,
    Paragraph2,
    Paragraph4,
} from "baseui/typography";
import { Block } from "baseui/block";
import { THEME } from "../utils/Constants";
import "./AboutScreen.css";
import { Helmet } from "react-helmet";

interface AboutScreenProps {
    theme: string;
}

const AboutScreen = ({ theme }: AboutScreenProps) => {
    return (
        <>
            <Helmet>
                <title>About</title>
                <meta name="description" content="Information about PathLess" />
            </Helmet>
            <Block
                backgroundColor={
                    theme === THEME.dark ? "rgb(25,25,25)" : "#FFF"
                }
                paddingTop="50px"
                paddingBottom="50px"
            >
                <Block
                    marginLeft="auto"
                    marginRight="auto"
                    maxWidth={["80%", "80%", "70%", "50%"]}
                    className="flex flex-col gap-4"
                >
                    <Display3 $style={{ textAlign: "center" }}>
                        About PathLess
                    </Display3>
                    <H5>What is PathLess?</H5>
                    <Paragraph2>
                        People naturally enjoy being outside. The problem is,
                        walking along the same path time and time again becomes
                        boring. This is a way to create new routes, based on a
                        given distance the user wishes to travel. Think of it
                        like this. You want to bike 3 miles today, but are tired
                        of the same route. You don't have a path in mind, so you
                        use PathLess to create one for you. Scrolling through
                        the instructions, you go ahead and start on your course.
                    </Paragraph2>
                    <H5>How it Works</H5>
                    <Paragraph2>
                        On a high level, this uses Mapbox, a service that
                        renders the map, provides the search feature, and the
                        routing/diections. The site interfaces with this with
                        given data (from the control dialog and search) to
                        display routes meeting the logic for creating the route.
                    </Paragraph2>

                    <Paragraph2>
                        This is the second version of this project. The first
                        was a proof of concept, using HTML/CSS/JS to build the
                        UI. The focus was on the map, and the
                        routing/directions. This version is using
                        React/Typescript, with backend integration, in the hopes
                        of adding more features and fleshing out the
                        functionality.
                    </Paragraph2>

                    <Paragraph2>The logic has two main parts:</Paragraph2>

                    <ol>
                        <Paragraph2>
                            <li>
                                <Label1>Getting User Input</Label1>
                                This is done through the control dialog and the
                                search bar (geocoding) to figure out the
                                start/end location and then the button calls the
                                function to make queries (described below).
                            </li>
                        </Paragraph2>

                        <Paragraph2>
                            <li>
                                <Label1>Generating the Route</Label1>A series of
                                get requests are made to Mapbox Directions API,
                                adding points using Turf.js (since those calls
                                are free) within a boundary and then verifying
                                distance. After a certain number of calls or if
                                the numbers are out of scope, the process starts
                                again.
                            </li>
                        </Paragraph2>
                    </ol>
                    <H5>Design Document</H5>
                    <Paragraph2>
                        This is the second iteration of PathLess. The previous
                        version had its own separate document; however, the
                        algorithm remains largely the same. Both are linked
                        here:{" "}
                        <a
                            href={
                                process.env.PUBLIC_URL +
                                "/assets/design-document-pathless-v1.pdf"
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            design document v1
                        </a>
                        {" and "}
                        <a
                            href={
                                process.env.PUBLIC_URL +
                                "/assets/design-document-pathless-v2.pdf"
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            design document v2
                        </a>
                        .
                    </Paragraph2>
                    <H5>FAQ</H5>
                    <ul className="flex flex-col gap-4 list-disc">
                        <Paragraph2>
                            <li>
                                <Label1>
                                    Why do I get a notification requesting my
                                    current location?
                                </Label1>
                                Don't worry; this data is not stored. This
                                relocates the map to wherever you currently are,
                                so that you don't have to search up or manually
                                drag. The default location (upon first load,
                                before giving/denying permission) is the
                                University of California, Berkeley, where I
                                happen to study electrical engineering and
                                computer science + business.
                            </li>
                        </Paragraph2>

                        <Paragraph2>
                            <li>
                                <Label1>
                                    Sometimes, the request is using old points;
                                    other times, it's totally random. Why?
                                </Label1>
                                The procedure for generating points that the
                                route goes through is totally random. At times,
                                it may seem like it's planned; however, based on
                                the location, roads available, and distance, it
                                may vary.
                            </li>
                        </Paragraph2>
                        <Paragraph2>
                            <li>
                                <Label1>
                                    My request isn't going through. When I
                                    reload, it still isn't working. What do I
                                    do?
                                </Label1>
                                It may be the case that the number of API
                                requests Mapbox limits users to has been met. If
                                this is the case, no one can make any calls
                                (including the application) until the end of the
                                billing cycle.
                            </li>
                        </Paragraph2>
                        <Paragraph2>
                            <li>
                                <Label1>
                                    Why do the requests take so long?
                                </Label1>
                                Good question. Most Maps APIs are designed to
                                give distance and directions presuming the
                                locations are known; in this, it's the opposite,
                                which requires a query every time to get the
                                locations and check distance. The algorithm
                                itself is asynchronous, meaning you are still
                                able to interact with your browser, device, and
                                the map while the route is being loaded. When
                                ready, it will appear as though it was magic.
                            </li>
                        </Paragraph2>
                        <Paragraph2>
                            <li>
                                <Label1>
                                    This is cool. Do you have any other projects
                                    like this?
                                </Label1>
                                Of course! You can find a list of other projects
                                and link to source code/demos here:{" "}
                                <a
                                    href="https://aniruthn.com/projects"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    aniruthn.com/projects
                                </a>
                            </li>
                        </Paragraph2>
                    </ul>
                    <H5>Feedback</H5>
                    <Paragraph2>
                        Have ideas for how this can be improved? Let me know at
                        aniruth.n@berkeley.edu and I'll try working on it. For
                        the future, I'm currently thinking about uses to
                        implement an authentication stack, saving and exporting
                        route data, and integrations with other services.
                    </Paragraph2>
                    <Paragraph2>Designed by Aniruth Narayanan</Paragraph2>
                    <Paragraph2>
                        Built with React, Mapbox, Firebase, BaseWeb, Typescript
                    </Paragraph2>
                    <Paragraph4>
                        © Aniruth Narayanan. All rights reserved. No template
                        used.
                    </Paragraph4>
                </Block>
            </Block>
        </>
    );
};

export default AboutScreen;
