import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { useState, useRef, useCallback, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import MapGL, { Source, Layer, Marker } from "react-map-gl";
//@ts-ignore
import Geocoder from "react-map-gl-geocoder";
import createPath from "../utils/CreatePath";
import MarkerImage from "../marker-icons/mapbox-marker-icon-blue.svg";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE as MODAL_SIZE,
    ROLE,
} from "baseui/modal";
import { Label2, Paragraph2 } from "baseui/typography";
import { Input } from "baseui/input";
import { Button, KIND as ButtonKind, SHAPE } from "baseui/button";
import { Card } from "baseui/card";
import { toaster, ToasterContainer } from "baseui/toast";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { useMediaQuery } from "react-responsive";
import ArrowImage from "../img/arrow.png";

// @ts-ignore
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

interface MapProps {
    theme: string;
}

const walkingSpeed = 3;
const cyclingSpeed = 6;
const drivingSpeed = 10;

const MapboxGLMap = ({ theme }: MapProps) => {
    const removeButtonBg = () => {
        const button = document.getElementsByClassName(
            "mapboxgl-ctrl-geocoder--button"
        )[0] as HTMLElement;
        if (button) {
            button.style.background = "none";
        }
    };

    const [viewport, setViewport] = useState({
        latitude: 37.8719,
        longitude: -122.2585,
        zoom: 12,
    });
    const [start, setStart] = useState({
        latitude: 37.8719,
        longitude: -122.2585,
        zoom: 12,
    });
    const mapRef = useRef<any>();
    const handleViewportChange = useCallback(
        (newViewport) => setViewport(newViewport),
        []
    );

    const [geoJSON, setGeoJSON] = useState<any>(null);
    const [data, setData] = useState<any>(null);

    // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
    const handleGeocoderViewportChange = useCallback(
        (newViewport) => {
            const geocoderDefaultOverrides = { transitionDuration: 1000 };
            setStart(newViewport);
            setGeoJSON(null);

            return handleViewportChange({
                ...newViewport,
                ...geocoderDefaultOverrides,
            });
        },
        [handleViewportChange]
    );

    const [distance, setDistance] = useState(3);

    const [profile, setProfile] = useState("cycling");

    const getPath = async () => {
        const arr = await createPath(
            [start.longitude, start.latitude],
            distance,
            profile
        );
        setGeoJSON(arr[0]);
        setData(arr[1]);
        const msg = "Route Generated!";
        toaster.info(<>{msg}</>, {
            onClose: () => console.log("Toast closed."),
            overrides: {
                InnerContainer: {
                    style: { width: "100%" },
                },
            },
        });
        console.log(arr[0]);
        console.log(arr[1]);
    };

    const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

    const Directions = () => {
        if (!data) {
            return <></>;
        }
        let directions: any[] = [];
        const legs = data.legs;
        legs.forEach((leg: any) => {
            const steps = leg.steps;
            steps.forEach((step: any) => {
                directions.push(step.maneuver.instruction);
            });
        });

        return (
            <div
                style={{
                    position: "absolute",
                    zIndex: 1,
                    maxWidth: isMobile ? "45%" : "30%",
                    left: "10px",
                    bottom: isMobile ? "20%" : "30%",
                    top: "calc(80px + " + isMobile ? 130 : 0 + "px)",
                }}
            >
                <Card
                    overrides={{
                        Root: {
                            style: { maxHeight: "100%", overflowY: "scroll" },
                        },
                    }}
                >
                    <Label2>Directions</Label2>
                    {directions.map((instruction: any, index: number) => (
                        <Paragraph2 key={index}>
                            <li className="mt-2">{instruction}</li>
                        </Paragraph2>
                    ))}
                </Card>
            </div>
        );
    };

    // Load arrow image into map
    useEffect(() => {
        if (mapRef === undefined) return;
        const node = mapRef.current;
        const map = node?.getMap();
        map.loadImage(ArrowImage, (err: any, image: any) => {
            if (err) throw err;
            map.addImage("arrow", image);
        });
    }, [mapRef]);

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button
                $style={{
                    position: "absolute",
                    zIndex: 1,
                    marginTop: "70px",
                    marginLeft: "10px",
                }}
                onClick={() => setIsOpen(true)}
                shape={SHAPE.pill}
            >
                Load Route
            </Button>
            <Directions />
            <Modal
                onClose={() => setIsOpen(false)}
                closeable
                isOpen={isOpen}
                animate
                autoFocus
                size={MODAL_SIZE.default}
                role={ROLE.dialog}
                overrides={{ Root: { style: { zIndex: 100 } } }}
            >
                <ModalHeader>Load Route</ModalHeader>
                <ModalBody>
                    <div className="grid grid-cols-2 gap-6 mb-4">
                        <div>
                            {/* TODO: Add in error handling for decimal and negative values - treat state objects as strings and then cast to numbers when appropriate later on */}
                            <Paragraph2 className="mb-4">
                                Distance (Miles)
                            </Paragraph2>
                            <Input
                                placeholder="Distance"
                                value={distance}
                                onChange={(e: any) => {
                                    let newValue = Number(e.target.value);
                                    if (Number.isNaN(newValue)) {
                                        newValue = 0;
                                        const msg =
                                            "Distances cannot be negative.";
                                        toaster.info(<>{msg}</>, {
                                            onClose: () =>
                                                console.log("Toast closed."),
                                            overrides: {
                                                InnerContainer: {
                                                    style: { width: "100%" },
                                                },
                                            },
                                        });
                                    }
                                    setDistance(newValue);
                                }}
                            />
                        </div>
                        <div>
                            <Paragraph2 className="mb-4">
                                Duration (Minutes)
                            </Paragraph2>
                            <Input
                                placeholder="Distance"
                                value={
                                    profile === "walking"
                                        ? (distance * 60) / walkingSpeed
                                        : profile === "cycling"
                                        ? (distance * 60) / cyclingSpeed
                                        : (distance * 60) / drivingSpeed
                                }
                                onChange={(e: any) => {
                                    let newValue = Number(e.target.value);
                                    if (Number.isNaN(newValue)) {
                                        newValue = 0;
                                        const msg =
                                            "Duration cannot be negative.";
                                        toaster.info(<>{msg}</>, {
                                            onClose: () =>
                                                console.log("Toast closed."),
                                            overrides: {
                                                InnerContainer: {
                                                    style: { width: "100%" },
                                                },
                                            },
                                        });
                                    }
                                    newValue =
                                        profile === "walking"
                                            ? (newValue * walkingSpeed) / 60
                                            : profile === "cycling"
                                            ? (newValue * cyclingSpeed) / 60
                                            : (newValue * drivingSpeed) / 60;
                                    setDistance(newValue);
                                }}
                            />
                        </div>
                    </div>
                    <Paragraph2>Profile</Paragraph2>
                    <RadioGroup
                        value={profile}
                        onChange={(e) => setProfile(e.currentTarget.value)}
                        align={ALIGN.vertical}
                    >
                        <Radio value="walking">Walking</Radio>
                        <Radio value="cycling">Cycling</Radio>
                        <Radio value="driving">Driving</Radio>
                    </RadioGroup>
                </ModalBody>
                <ModalFooter>
                    <ModalButton
                        onClick={() => setIsOpen(false)}
                        kind={ButtonKind.tertiary}
                    >
                        Cancel
                    </ModalButton>
                    <ModalButton
                        onClick={() => {
                            if (distance > 0 && distance < 15) {
                                getPath();
                            } else {
                                const msg =
                                    distance + " is an invalid distance.";
                                toaster.info(<>{msg}</>, {});
                            }
                            setIsOpen(false);
                        }}
                    >
                        Generate
                    </ModalButton>
                </ModalFooter>
            </Modal>
            <div
                style={{
                    height: "100vh",
                    position: "relative",
                }}
            >
                <MapGL
                    //   @ts-ignore
                    ref={mapRef}
                    {...viewport}
                    width="100%"
                    height="100%"
                    onViewportChange={handleViewportChange}
                    mapboxApiAccessToken={mapboxgl.accessToken}
                    mapStyle={"mapbox://styles/mapbox/" + theme}
                    // can also use streets-v11 for light mode
                >
                    <Marker
                        longitude={start.longitude}
                        latitude={start.latitude}
                        offsetTop={-24}
                    >
                        <img src={MarkerImage} alt="Marker" />
                    </Marker>
                    {geoJSON && (
                        <Source id="route" type="geojson" data={geoJSON}>
                            <Layer
                                id="line-route"
                                type="line"
                                paint={{
                                    "line-color": "#3887be",
                                    "line-width": 5,
                                    "line-opacity": 0.75,
                                }}
                            />
                            <Layer
                                id="marker"
                                type="symbol"
                                paint={{}}
                                layout={{
                                    "symbol-placement": "line",
                                    "symbol-spacing": 2,
                                    "icon-image": "arrow",
                                    "icon-size": 0.045,
                                    visibility: "visible",
                                    "icon-allow-overlap": true,
                                }}
                            />
                        </Source>
                    )}
                    <Geocoder
                        mapRef={mapRef}
                        marker={false}
                        trackProximity
                        clearAndBlurOnEsc
                        // collapsed
                        onViewportChange={handleGeocoderViewportChange}
                        mapboxApiAccessToken={mapboxgl.accessToken}
                        position="top-right"
                        onLoad={removeButtonBg}
                    />
                </MapGL>
            </div>
            <ToasterContainer autoHideDuration={3000} />
        </>
    );
};

export default MapboxGLMap;
